<template>
  <div v-if="klant" class="flex-shrink-1 lg:flex lg:gap-2">
    <span>
      <component :is="klant.id > 0 ? 'router-link' : 'span'" :to="klant.id > 0 ? `/klanten/info/${klant.id}` : undefined">
        <b v-if="label">{{ label }}:</b> {{ `${klant.firstname || ''} ${klant.lastname || ''}`.trim() || '?' }}
      </component>
      <i
        v-show="hasAccount"
        class="ml-2 text-xs"
        :class="{ 'fas fa-user-plus text-green-500': hasAccount, 'fas fa-user-minus text-gray-400': !hasAccount }"
      ></i>
    </span>
    <ButtonPhoneEmail :phone="klant.phone" :email="klant.email" />
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'

import ButtonPhoneEmail from '@/components/UI/Button/PhoneEmail'

const props = defineProps({
  klant: Object,
  label: {
    type: String,
    default: 'Klant',
  },
})

const hasAccount = computed(() => (typeof props.klant?.password !== 'undefined'))
</script>
